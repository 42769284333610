import React from 'react';

import styleConstants from '../../constants/styleConstants';
import infoConstants from '../../constants/infoConstants';


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
//     renderFooter
//------------------------------------------
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
export default function renderFooter() {

    return (
        <>
            <div className="footer-container">
                <div className="footer-text">{infoConstants.copyRight}</div>
            </div>
            
            <style jsx>{`
                .footer-container {
                    background-color: #4030b7;
                    width: 100%;
                    color: #fff;
                    justify-content: flex-end;
                    padding: 20px 60px 20px 82px;
                
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .footer-container {
                        width: 100%;
                        max-width: 100%;
                        padding: 20px;
                    }
                }
                
                .footer-text {
                    display: flex;
                    justify-content: right;
                    font: 400 12px Work Sans, sans-serif;
                }
                
              `}</style>
            
        </>
    );

}//END renderFooter
