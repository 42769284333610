import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from "../actions";


import Header from './Header';
import Landing from './Landing';
import Adoption from "./Adoption";
import Volunteer from "./Volunteer";
import Donations from "./Donations";
import Resources from "./Resources";
import About from "./About";
import Contact from "./Contact";


const Store = () => <h2>Store</h2>;

const MyAccount = () => (
    <div style={{"align-content": "center"}}>
        <h2 style={{"text-align": "center"}}>
            User Profiles 
            <br/>
            Coming Soon!
        </h2>
    </div>
);

    

class App extends Component {
    
    componentDidMount() {
        
        // First things first, fetch the user!
        //this.props.fetchUser();
    
    }//END componentDidMount
    
    
    render() {
        return (
            <div className="container">
                <BrowserRouter>
                    <div>
                        <Header />
                        <Route exact path="/" component={Landing} />
                        <Route exact path="/home" component={Landing} />
                        <Route exact path="/store" component={Store} />
                        <Route exact path="/adoption" component={Adoption} />
                        <Route exact path="/volunteer" component={Volunteer} />
                        <Route exact path="/donations" component={Donations} />
                        <Route exact path="/resources" component={Resources} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/myaccount" component={MyAccount} />
                    </div>
                </BrowserRouter>    
            </div>
        );
    }//render

}//class App

export default connect(null, actions)(App);
