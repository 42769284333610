import React from "react";
import PropTypes from "prop-types";

import styleConstants from "../../constants/styleConstants";


export default class AdoptionCard extends React.Component {

    
    static propTypes = {
        name: PropTypes.string.isRequired,
        image: PropTypes.any.isRequired,
        description: PropTypes.string.isRequired,
        onClickCallback: PropTypes.any
    };
    
    
    static defaultProps = {
        onClickCallback: undefined
    };


    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     constructor
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    constructor(props) {
        
        super(props);
        
        this.state = {
            onClickCallback: this.onClickAdoptee,
        };
        
    }//END constructor

    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     onClickAdoptee
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    onClickAdoptee = (name) => {

        const { onClickCallback } = this.props;
        
        if (onClickCallback)
            onClickCallback(name);
        
    }//END onClickAdoptee    


    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render(){
        
        const { onClickCallback } = this.state;
        const { name, image, description } = this.props;
        
        // If there is an onClickCallback in props, make the image clickable
        const className = this.props.onClickCallback ? "adoptee-image clickable" : "adoptee-image";
        
        return (
            <>
                <div className="landing-adopt">
                    <div className="round-border">
                        <img
                            alt={name}
                            onClick={() => { onClickCallback(name) }}
                            loading="lazy"
                            srcSet={image}
                            className={className}
                        />
                        <div className="landing-adoptee-name">{name}</div>
                        <div className="landing-adoptee-desc">
                            {description}
                        </div>
                    </div>
                </div>

                <style jsx="true">{`

                    .landing-adopt {
                        display: flex;
                        flex-direction: column;
                        line-height: normal;
                        width: 100%;
                        margin-top: 32px;
                        margin-bottom: 48px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .landing-adopt {
                            width: 100%;
                        }
                    }

                    .round-border {
                        justify-content: center;
                        align-items: center;
                        border-radius: 2.927px;
                        background-color: #fff;
                        display: flex;
                        flex-grow: 1;
                        flex-direction: column;
                        width: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .round-border {
                            margin-top: 34px;
                        }
                    }

                    .adoptee-image {
                        aspect-ratio: 1;
                        object-fit: auto;
                        object-position: center;
                        width: 192px;
                    }
                    
                    .clickable {
                        cursor: pointer;
                    }

                    .landing-adoptee-name {
                        color: var(--Neutral-700, #3d445c);
                        margin-top: 13px;
                        font: 700 15px Work Sans, sans-serif;
                    }

                    .landing-adoptee-desc {
                        color: var(--Neutral-400, #858ead);
                        display: flex;
                        align-self: center;
                        text-align: center;
                        margin-top: 13px;
                        width: 80%;
                        font: 400 12px Work Sans, sans-serif;
                    }

                `}</style>
            </>
        );
    
    }//END render

    
}//END AdoptionCard
