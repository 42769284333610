import React, { Component } from 'react';
import { connect } from 'react-redux';

import infoConstants from "../constants/infoConstants";
import styleConstants from "../constants/styleConstants";
import renderSocials from "./common/RenderSocials";
import renderFooter from "./common/RenderFooter";

import banner from "../assets/images/banners/donations-banner.png";
import donateWithPayPal from "../assets/images/donate-with-paypal.png";
import suppliesExample from "../assets/images/supplies-example.jpg";
import gfcrLogo from "../assets/images/gfcr-logo[128x128].png";



class Donations extends Component {

    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderBanner
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderBanner() {

        return (
            <>
                <div className="banner-container">
                    <img
                        loading="lazy"
                        srcSet={banner}
                        className="banner-img"
                    />
                    <div className="banner-title-text">
                        How You Can Help
                    </div>
                </div>
                <style jsx="true">{`
                    .banner-container {
                        display: flex;
                        flex-direction: column;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        position: relative;
                        width: 100%;
                        align-items: start;
                        overflow: hidden;
                        min-height: 557px;
                        color: #fff;
                        justify-content: center;
                        padding: 206px 60px 168px 87px;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .banner-container {
                            max-width: 100%;
                            padding: 40px 20px 40px 30px;
                        }
                    }
                    .banner-img {
                        position: absolute;
                        inset: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                    .banner-title-text {
                        position: absolute;
                        top: 10%;
                        right: 10%;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        color: #fff;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .banner-title-text {
                            left: 10%;
                        }
                    }

                `}</style>
            </>
        );

    }//END renderBanner

    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderDescription
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderDescription() {

        return (
            <>
                
                <div className="volunteer-container">
                    
                    <div className="volunteer-title">Donate</div>
                    <div className="volunteer-desc-container1"> 
                        <div className="volunteer-desc-container2">
                            <div className="volunteer-paragraph-text">
                                Your generous donations play a crucial role in transforming the 
                                lives of feral cats and making a tangible impact on our feline rescue
                                efforts. With your support, we can provide essential medical care, 
                                including vaccinations and spay/neuter procedures, ensuring the health
                                and well-being of these vulnerable cats. Your donations also help us 
                                provide nutritious food, safe shelter, and comfortable bedding,
                                offering feral cats a chance to thrive. By contributing, you directly
                                contribute to our TNR (Trap-Neuter-Return) programs, which effectively
                                manage feral cat populations, preventing the suffering and overbreeding
                                of these animals. Additionally, your donations enable us to rescue,
                                rehabilitate, and find loving homes for feral cats who are able to
                                transition into domestic life. Together, we can create a compassionate
                                community where every feral cat receives the care and love they deserve.
                                We sincerely appreciate your support and compassion for these remarkable creatures.
                                <br />
                                <br />
                                See below for some of the meaningful ways in which you can help out:
                            </div>
                        </div>
                    </div>
                    
                    <div className="volunteer-paragraph-container1">
                        <div className="volunteer-paragraph-title">Shelter Volunteers</div>
                        <div className="volunteer-desc-container2">
                            <div className="volunteer-paragraph-text">
                                Shelter Volunteers are our most needed type of volunteer. Shelter
                                Volunteers work on a rotating weekly schedule that best suites them.
                                You could volunteer weekly, bi-weekly or even just once a month –
                                whatever works best for you! Shelter Volunteers are responsible for
                                tending to the cats in our care at the shelter. This includes feeding,
                                cleaning litter boxes, keeping the shelter tidy and socialization of
                                our resident cats. When you ask other shelter volunteers, they’ll tell
                                you the job is extremely fulfilling – especially watching first hand a
                                cat who arrived scared and shut down begin to open up, trust humans
                                and accept affection.
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="volunteer-paragraph-container1">
                        <div className="volunteer-paragraph-title">Rescue</div>
                        <div className="volunteer-desc-container2">
                            <div className="volunteer-paragraph-text">
                                    Typically, once we receive a report of feral cats or kittens, we need
                                    local volunteers to trap them and transport them to the Rescue or a
                                    foster home. The animals will also require rides to and from the
                                    veterinary clinic.
                            </div>
                        </div>
                    </div>

                    <div className="volunteer-paragraph-container1">
                        <div className="volunteer-paragraph-title">Rehabilitation</div>
                        <div className="volunteer-desc-container2">
                            <div className="volunteer-paragraph-text">
                                Most of these animals will need foster homes arranged for them. These
                                homes must be stocked with food, litter, and other supplies, which
                                also have to be purchased and delivered.
                            </div>
                        </div>
                    </div>


                    <div className="volunteer-paragraph-container1">
                        <div className="volunteer-paragraph-title">Finding Homes</div>
                        <div className="volunteer-desc-container2">
                            <div className="volunteer-paragraph-text">
                                We rely heavily on our website and Facebook to advertise our adoptable
                                animals and keep you posted on the others. It takes many hours each
                                day to keep both of them updated and also respond to all the inquiries
                                that we receive. However, the advantage of using computers means that
                                volunteers can do the work at any time and from any location!
                                <br />
                                <br />
                                As you can see, almost anyone can play a part in our operation. The
                                more volunteers we have, the better we are able to respond to the
                                needs of feral cats and kittens in our area. Whatever your interests
                                or personal availability, please CONTACT US to see how you can help
                                <br />
                            </div>
                        </div>
                    </div>               
                    
                    <div className={"volunteer-desc-container1"}>
                        <div className="volunteer-call-to-action-btn">VOLUNTEER TODAY!</div>
                    </div>
                    
                </div>
                
                <style jsx="true">{`
                    
                    .volunteer-container {
                        //background-color: var(--Neutral-800, #97aef1);
                        align-self: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 48px;
                        width: 100%;
                        //max-width: 812px;
                        flex-direction: column;
                        padding: 0 20px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-container {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }

                    .volunteer-title {
                        color: var(--Neutral-800, #292e3d);
                        text-align: center;
                        align-self: center;
                        font: 700 28px Work Sans, sans-serif;
                    }

                    .volunteer-desc-container1 {
                        margin-top: 64px;
                        max-width: 812px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-desc-container1 {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }

                    .volunteer-desc-container2 {
                        gap: 20px;
                        display: flex;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-desc-container2 {
                            flex-direction: column;
                            align-items: stretch;
                            gap: 0px;
                        }
                    }  
                    
                    .volunteer-desc-text {
                        color: var(--Neutral-600, #525b7a);
                        font: 400 16px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-desc-text {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }                    
                    
                    .volunteer-desc-column1 {
                        display: flex;
                        flex-direction: column;
                        line-height: normal;
                        width: 72%;
                        margin-left: 0px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-desc-column1 {
                            width: 100%;
                        }
                    }
                    
                    .volunteer-desc-column2 {
                        display: flex;
                        flex-direction: column;
                        line-height: normal;
                        width: 28%;
                        margin-left: 20px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-desc-column2 {
                            width: 100%;
                        }
                    }

                    .volunteer-call-to-action-btn {
                        justify-content: center;
                        border-radius: 2.927px;
                        background-color: #ff7727;
                        margin-top: 17px;
                        color: var(--Neutral-White, #fff);
                        text-align: center;
                        //width: 100%;
                        padding: 12px;
                        font: 600 18px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-call-to-action-btn {
                            margin-top: 40px;
                        }
                    }

                    .volunteer-paragraph-container1 {
                        margin-top: 32px;
                        max-width: 812px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-paragraph-container1 {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }                  
                    .volunteer-paragraph-title {
                        color: var(--Neutral-800, #292e3d);
                        margin-top: 70px;
                        font: 700 28px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-paragraph-title {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }

                    .volunteer-paragraph-text {
                        color: var(--Neutral-600, #525b7a);
                        margin-top: 26px;
                        font: 400 16px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-paragraph-text {
                            max-width: 100%;
                        }
                    }

                `}</style>
            </>
        );        
        
    }//END renderDescription

    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderBlurbs
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderBlurbs() {
        return (
            <>
                                
                <div className="main-container">

                    <div className="main-container-2">
                    
                        <div className="main-title">Donate</div>
                        <div className="desc-container1">
                            <div className="paragraph-container2">
                                <div className="paragraph-text">
                                    Your generous donations play a crucial role in transforming the lives
                                    of feral cats and making a tangible impact on our feline rescue
                                    efforts. With your support, we can provide essential medical care,
                                    including vaccinations and spay/neuter procedures, ensuring the health
                                    and well-being of these vulnerable cats. Your donations also help us
                                    provide nutritious food, safe shelter, and comfortable bedding,
                                    offering feral cats a chance to thrive. By contributing, you directly
                                    contribute to our TNR (Trap-Neuter-Return) programs, which effectively
                                    manage feral cat populations, preventing the suffering and
                                    overbreeding of these animals. Additionally, your donations enable us
                                    to rescue, rehabilitate, and find loving homes for feral cats who are
                                    able to transition into domestic life. Together, we can create a
                                    compassionate community where every feral cat receives the care and
                                    love they deserve. We sincerely appreciate your support and compassion
                                    for these remarkable creatures.
                                    <br />
                                    <br />
                                    See below for some of the meaningful ways in which you can help out:
                                </div>
                            </div>
                        </div>
    
                        <div className="paragraph-container1">
                            <div className="paragraph-title">Monetary Donations</div>
                            <div className="paragraph-container2">
                                <div className="key-value-pair-list">
                                    <span style={{fontWeight: 700}}>Cash:</span> Donations can be dropped
                                    off at the main office in [<i>{infoConstants.geoAddress}</i>], where they will be credited to our
                                    account. Please indicate that the funds are for Gracie’s Feral Cat
                                    Rescue.
                                    <br />
                                    <br />
                                    <span style={{fontWeight: 700}}>Cheques/Money Orders:</span> Cheques
                                    or money orders, made payable to Gracie’s Feral Cat Rescue, can be
                                    mailed to [<i>{infoConstants.geoAddress}</i>].
                                    <br />
                                    <br />
                                    <span style={{fontWeight: 700}}>E-Transfers:</span> Online bank
                                    transfers can be sent to [<i>{infoConstants.emailAddress}</i>].
                                    <br />
                                    <br />
                                    {/*<span style={{fontWeight: 700}}>Paypal:</span> Make a donation using*/}
                                    {/*your Paypal account or credit card.*/}
                                </div>
                            </div>
                        </div>
                        
                        {/*<img*/}
                        {/*    loading="lazy"*/}
                        {/*    srcSet={donateWithPayPal}*/}
                        {/*    className="donate-with-paypal-btn"*/}
                        {/*/>*/}
                        <div>
                            <br />
                            <br />
                        </div>
                        <div className="paragraph-container1">
                            <div className="column-container">
                                <div className="supplies-column1">
                                    <div className="paragraph-title-no-top-margin">Food / Supplies Donations</div>
                                    <div className="bullet-list-container">
                                        • Wet Cat Food (Friskies Pate Preferred)
                                        <br />
                                        • Dry Cat Food (Whiskas Preferred)
                                        <br />
                                        • Kitten Dry Food (Kitten Chow Preferred)
                                        <br />
                                        • Temptations Cat Treats
                                        <br />
                                        • Lickable Squeeze Up Treats (A cat favourite!)
                                        <br />
                                        • Cat Toys (Balls, Cat Nip Toys, Feather Chasers, etc.)
                                        <br />
                                        • Litter, Litter Boxes & Scoops
                                        <br />
                                        • Paper Towels
                                        <br />
                                        • Garbage Bags
                                        <br />
                                        • Dish Liquid
                                        <br />
                                        • Cleaners
                                        <br />
                                        • Disinfectant Wipes
                                    </div>
                                </div>
                                <div className="supplies-column2">
                                    <img
                                        loading="lazy"
                                        srcSet={suppliesExample}
                                        className="supplies-img"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="paragraph-container1">
                            <div className="paragraph-title">Alternate Ways To Give</div>
    
                                <div className="paragraph-container2">
                                    <div className="key-value-pair-list">
                                        <span style={{fontWeight: 700}}>Canadian Tire Money:</span> Donate
                                        your Canadian Tire money and we can use it to buy much-needed supplies
                                        such as cleaners, paper towels, and kitty litter. Every dollar we save
                                        goes to help another animal.
                                        <br />
                                        <br />
                                        <span style={{fontWeight: 700}}>Recyclables:</span> Save your
                                        recyclables and bring them to [_____], where the proceeds can be
                                        credited to Gracie’s Feral Cat Rescue account.
                                        <br />
                                        <br />
                                        <span style={{fontWeight: 700}}>Donation Cans:</span> Wondering what
                                        to do with your spare change? Be on the lookout for our Gracie’s Feral
                                        Cat Rescue donation cans at local businesses.
                                    </div>
                                </div>
    
                        </div>
                        <div>
                            <br />
                            <br />
                            <br />
                        </div>
                    
                        
                    </div>
                </div>
                
                <style jsx="true">{`
                .main-container {
                    align-self: center;
                    display: flex;
                    justify-content: center;
                    justify-items: center;
                    align-content: center;
                    align-items: center;
                    margin-top: 48px;
                    width: 100%;
                    //max-width: 812px;
                    flex-direction: column;
                    padding: 0 20px;
                }
                
                .main-container-2 {
                    align-self: center;
                    display: flex;
                    justify-content: center;
                    justify-items: center;
                    align-content: center;
                    align-items: center;
                    width: 100%;
                    flex-direction: column;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .main-container-2 {
                        width: 80%;
                    }
                }
                

                .main-title {
                    color: var(--Neutral-800, #292e3d);
                    text-align: center;
                    align-self: center;
                    font: 700 28px Work Sans, sans-serif;
                }

                .desc-container1 {
                    margin-top: 64px;
                    justify-content: center;
                    justify-items: center;
                    align-self: center;
                    align-content: center;
                    align-items: center;
                    max-width: 812px;
                }
                
                .paragraph-container2 {
                    gap: 20px;
                    display: flex;
                    justify-content: center;
                    justify-items: center;
                    align-self: center;
                    align-content: center;
                    align-items: center;
                }

                .desc-text {
                    color: var(--Neutral-600, #525b7a);
                    font: 400 16px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .desc-text {
                        max-width: 100%;
                        margin-top: 40px;
                    }
                }

                .desc-column1 {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                    width: 72%;
                    margin-left: 0px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .desc-column1 {
                        width: 100%;
                    }
                }

                .desc-column2 {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                    width: 28%;
                    margin-left: 20px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .desc-column2 {
                        width: 100%;
                    }
                }
                
                .paragraph-container1 {
                    margin-top: 32px;
                    max-width: 812px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .paragraph-container1 {
                        max-width: 100%;
                        margin-top: 40px;
                    }
                }
                .paragraph-container2 {
                    gap: 20px;
                    display: flex;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .paragraph-container2 {
                        flex-direction: column;
                        align-items: stretch;
                        gap: 0px;
                    }
                }
                
                .paragraph-title {
                    color: var(--Neutral-800, #292e3d);
                    margin-top: 70px;
                    font: 700 28px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .paragraph-title {
                        max-width: 100%;
                        margin-top: 40px;
                    }
                }

                .paragraph-text {
                    color: var(--Neutral-600, #525b7a);
                    justify-content: center;
                    justify-items: center;
                    align-self: center;
                    align-content: center;
                    align-items: center;
                    font: 400 16px Work Sans, sans-serif;
                }
                //@media (max-width: ${styleConstants.mobileScreenWidth}px) {
                //    .paragraph-text {
                //        width: 80%;
                //    }
                //}
                .donate-with-paypal-btn {
                    aspect-ratio: 3.13;
                    aligh-self: left;
                    object-fit: contain;
                    object-position: left;
                    width: 300px;
                    margin-top: 15px;
                    max-width: 100%;
                }
                
                .key-value-pair-list {
                    color: var(--Neutral-600, #525b7a);
                    margin-top: 20px;
                    justify-content: center;
                    justify-items: center;
                    align-self: center;
                    align-content: center;
                    align-items: center;
                    font: 400 16px Work Sans, sans-serif;
                }
                //@media (max-width: ${styleConstants.mobileScreenWidth}px) {
                //  .key-value-pair-list {
                //    width: 80%;
                //  }
                //}

                .div-6 {
                  margin-top: 65px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .div-6 {
                    max-width: 100%;
                    margin-top: 40px;
                  }
                }
                
                
                .column-container {
                  gap: 20px;
                  display: flex;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .column-container {
                    flex-direction: column;
                    align-items: stretch;
                    gap: 0px;
                  }
                }
                
                .paragraph-title-no-top-margin {
                  color: var(--Neutral-800, #292e3d);
                  font: 700 28px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .paragraph-title-no-top-margin {
                    max-width: 100%;
                  }
                }
                
                .bullet-list-container {
                  color: var(--Neutral-600, #525b7a);
                  margin-top: 48px;
                  font: 400 16px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .bullet-list-container {
                        max-width: 100%;
                        margin-top: 20px;
                    }
                }


                .supplies-column1 {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                    width: 60%;
                    margin-left: 0px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .supplies-column1 {
                        width: 100%;
                    }
                }                
                
                .supplies-column2 {
                  display: flex;
                  flex-direction: column;
                  line-height: normal;
                  width: 40%;
                  margin-left: 20px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .supplies-column2 {
                        width: 100%;
                        margin-left: 0px;  
                    }
                }
                
                .supplies-img {
                  aspect-ratio: 0.76;
                  object-fit: auto;
                  object-position: center;
                  width: 100%;
                  flex-grow: 1;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .supplies-img {
                    margin-top: 40px;
                  }
                }

              `}</style>
            </>
        );
    }//END renderBlurbs
    

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render() {

        return (
            <div>
                {this.renderBanner()}
                {this.renderBlurbs()}
                {renderSocials()}
                {renderFooter()}
            </div>
        );

    }//END render


}//END class Landing

export default connect()(Donations);