import React, {Component, useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Payments from "./Payments";

import styleConstants from "../constants/styleConstants";
import gfcrLogo from "../assets/images/gfcr-logo[128x128].png";
import userIcon from "../assets/icons/profile-icon-cyan.png";
import Hamburger from "./common/Hamburger";
import PropTypes from "prop-types";

class Header extends Component {
    
    
    selectedColor = "#f95aec";
    
    
    
    static propTypes = {
        hamburgerState: PropTypes.bool,
        onHamburgerClick: PropTypes.func,  
    };
    
    
    state = {
        hamburgerState: false
    };
    
    
    //
    // CLICK EVENTS
    //
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     onHamburgerClick
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    onHamburgerClick() {
        
        let clicked = !this.state.hamburgerState;
        
        this.setState(
            {hamburgerState: clicked}
        );

    }//END onHamburgerClick    


    
    //
    // RENDER FUNCTIONS
    //
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderUserContent
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/    
    renderUserContent() {
    
        switch (this.props.auth) {
            case null:
                return;
            case false:
                console.log("FALSE");
                return <li><a href="/auth/google">Login With Google</a></li>;
            default:
                console.log("DEFAULT");
                return [
                    <li key="1"><Payments /></li>,
                    <li key="2" style={{margin: "0 10px"}}>
                        Credits: {this.props.auth.credits}
                    </li>,
                    <li key="3"><a href="/api/logout">Logout</a></li>
                ];
        }//END switch
    
    }//END renderUserContent
    

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderNavBar
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderNavBar() {
        
        return (
            <>

                <ul className="center">
        
                    <div className="wide-nav-div">
                        
                        <div className="hamburgerIcon" onClick={() => {this.onHamburgerClick()}}>
                            <Hamburger isOpen={this.state.hamburgerState} />
                        </div>
                        
                        <div className="hamburgerMenu">
                            <div className="title-nav-text-div">Menu</div>

                            <Link to={'/home'} className="reg-nav-text-div">Home</Link>
                            <Link to={'/adoption'} className="reg-nav-text-div">Adoption</Link>
                            <Link to={'/volunteer'} className="reg-nav-text-div">Volunteer</Link>
                            <Link to={'/donations'} className="reg-nav-text-div">Donations</Link>
                            <Link to={'/resources'} className="reg-nav-text-div">Resources</Link>
                            <Link to={'/about'} className="reg-nav-text-div">About</Link>
                            <Link to={'/contact'} className="reg-nav-text-div">Contact</Link>
                        </div>
                        
                        <Link to={'/home'} className="gfcr-logo-img">
                            <img
                                loading="lazy"
                                srcSet={gfcrLogo}
                                className="gfcr-logo-img"
                            />
                        </Link>

                        <div className="navtext-div">
                            <Link to={'/adoption'} className="reg-nav-text-div">Adoption</Link>
                            <Link to={'/volunteer'} className="reg-nav-text-div">Volunteer</Link>
                            <Link to={'/donations'} className="reg-nav-text-div">Donations</Link>
                            <Link to={'/resources'} className="reg-nav-text-div">Resources</Link>
                            <Link to={'/about'} className="reg-nav-text-div">About</Link>
                            <Link to={'/contact'} className="reg-nav-text-div">Contact</Link>
                        </div>

                        <Link to={'/myaccount'} className="user-icon-img">
                            <img
                                loading="lazy"
                                srcSet={userIcon}
                                className="user-icon-img"
                            />
                        </Link>
                    </div>
                    
                </ul>
                
                
                <style jsx="true">{`

                    .wide-nav-div {
                        background-color: white;
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                        color: var(--Neutral-900, #14171f);
                        font-weight: 400;
                        justify-content: space-between;
                        padding: 0 72px;
                        width: 100%;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .wide-nav-div {
                            //display: none;
                            //background-color: #f95aec;
                            justify-content: left;
                            padding: 0 0px;
                        }
                    }

                    .hamburgerIcon {
                        display: none;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .hamburgerIcon {
                            align-items: center;
                            align-self: flex-end;
                            display: flex;
                            z-index: 10;
                            padding: ${this.state.hamburgerState ? '8px 0% 0% 60%' : '8px 0% 0% 0%'};
                        }
                    }

                    .gfcr-logo-img {
                        aspect-ratio: 1;
                        object-fit: cover;
                        object-position: center;
                        width: auto;
                        max-width: 48px;
                        align-self: center;
                    }

                    .gfcr-logo-img:hover {
                        background-color: rgba(66, 241, 8, 0);
                        aspect-ratio: 1;
                        object-fit: cover;
                        object-position: center;
                        width: auto;
                        max-width: 48px;
                        align-self: center;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .gfcr-logo-img {
                            display: none;
                        }
                    }

                    .user-icon-img {
                        aspect-ratio: 1;
                        object-fit: cover;
                        object-position: center;
                        width: 42px;
                        align-self: center;
                    }

                    .user-icon-img:hover {
                        background-color: rgba(249, 90, 236, 0);
                        aspect-ratio: 1;
                        object-fit: cover;
                        object-position: center;
                        width: 42px;
                        align-self: center;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .user-icon-img {
                            display: none;
                        }
                    }

                    .hamburgerMenu {
                        display: none;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .hamburgerMenu {
                            background-color: #ffffff;
                            align-self: start;
                            align-items: flex-start;
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            position: absolute;
                            width: 60%;
                            height: 100%;
                            padding-top: 10px;
                            padding-left: 32px;
                            gap: 24px;
                            z-index: 9;
                            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);

                            transform: ${this.state.hamburgerState ? 'translateX(0%)' : 'translateX(-100%)'};
                            opacity: ${this.state.hamburgerState ? 1 : 0};
                        }
                    }

                    .navtext-div {
                        align-self: stretch;
                        display: flex;
                        gap: 48px;
                        justify-content: space-between;
                        margin: auto 0;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .navtext-div {
                            display: none;
                        }
                    }

                    .title-nav-text-div {
                        font-family: Work Sans, sans-serif;
                        font-size: 24px;
                        text-align: left;
                        color: #14171f;
                    }

                    .reg-nav-text-div {
                        font-family: Work Sans, sans-serif;
                        color: #14171f;
                    }

                `}</style>
            </>
        );
        
    }//END renderNavBar


    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/     
    render() {
        
        return (
            // <nav>
            //     <div className="nav-wrapper">
            //         <ul className="center">
                        this.renderNavBar()
            //         </ul>
            //     </div>
            //    
            // </nav>
        );
    }//END render
    
    
}//END class

function mapStateToProps(state) {

    return { 
        auth: state.auth,
        hamburgerState: state.hamburgerState
    };
    
}//END mapStateToProps


export default connect(mapStateToProps)(Header);
