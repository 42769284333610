import React, { Component } from 'react';

import AdoptionCarousel from "./AdoptionCarousel";

import styleConstants from "../constants/styleConstants";
import renderSocials from "./common/RenderSocials";
import renderFooter from "./common/RenderFooter";
import { getAdopteeInfoBlobByName } from "../i18n";


import adoptionBannerDefault from "../assets/images/banners/adoption-banner-default.png";
import adoptionBanner0 from "../assets/images/banners/adoption-banner-0.png";
import adoptionBanner1 from "../assets/images/banners/adoption-banner-1.png";
import adoptionBanner2 from "../assets/images/banners/adoption-banner-2.png";
import adoptionBanner3 from "../assets/images/banners/adoption-banner-3.png";
import infoConstants from "../constants/infoConstants";
import linkConstants from "../constants/linkConstants";



class Adoption extends Component {

    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     onAdopteeClick
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/    
    onAdopteeClick = (name) => {

        // Update the current adoptee
        this.setState({currentAdopteeName: name});

    }//END onClickAdoptee
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     constructor
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    constructor(props) {

        super(props);

        this.state = {
            currentAdopteeName: "Douglas",
            bannerImages: [
                adoptionBanner0,
                adoptionBanner1,
                adoptionBanner2,
                adoptionBanner3
            ]
        };

    }//END constructor
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     getAdopteeInfo
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    getAdopteeInfo = (name) => {
        
        const adopteeBlob = getAdopteeInfoBlobByName(name);
        
        // TODO: Error checking around this
        // Fill in the image object
        adopteeBlob.bannerImage = this.state.bannerImages[adopteeBlob.id];
        
        return adopteeBlob;
        
    }//END getBannerImage
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderBanner
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderBanner() {

        const { currentAdopteeName } = this.state;
        const { bannerImage } = this.getAdopteeInfo(currentAdopteeName);
        
        return (
            <>
                <div className="banner-container">
                    <img
                        loading="lazy"
                        srcSet={bannerImage}
                        className="banner-img"
                    />
                    <div className="banner-title-text">Adopt a special someone</div>
                </div>
                <style jsx="true">{`
                    .banner-container {
                        display: flex;
                        flex-direction: column;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        position: relative;
                        width: 100%;
                        align-items: start;
                        overflow: hidden;
                        min-height: 557px;
                        color: #fff;
                        justify-content: center;
                        padding: 206px 60px 168px 87px;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .banner-container {
                            max-width: 100%;
                            padding: 40px 20px 40px 30px;
                        }
                    }
                    .banner-img {
                        position: absolute;
                        inset: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                    .banner-title-text {
                        position: absolute;
                        top: 25%;
                        right: 10%;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        color: #fff;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .banner-title-text {
                            top: 25%;
                            left: 5%;
                        }
                    }
                    
                `}</style>
            </>
        );

    }//END renderBanner

    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderDescription
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderDescription() {
        
        const { currentAdopteeName } = this.state;
        const { title, paragraph1, paragraph2, paragraph3 } = this.getAdopteeInfo(currentAdopteeName);
        
        return (
            <>
                <div className="cat-desc-container">
                    <div className="cat-title-text">{title}</div>
                    <br />
                    <br />
                    <div className="cat-reg-text">
                        {paragraph1}
                    </div>
                    <br />
                    <div className="cat-reg-text">
                        {paragraph2}
                    </div>
                    <br />
                    <div className="cat-reg-text">
                        {paragraph3}
                    </div>
                </div>
                <style jsx="true">{`
                .cat-desc-container {
                    color: var(--Neutral-600, #525b7a);
                    justify-content: center;
                    justify-items: center;
                    align-self: center;
                    align-content: center;
                    align-items: center;
                    margin-top: 72px;
                    margin-bottom: 48px;
                    padding: 0 104px 0 88px;
                    font: 400 16px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .cat-desc-container {
                        padding: 0 64px 0 64px;
                    }
                }
                .cat-title-text {
                    color: var(--Neutral-900, #14171f);
                    font: 600 24px Work Sans, sans-serif;
                    align-self: center;
                    align-content: center;
                    width: 100%;
                }
                
                .cat-reg-text {
                    font: 400 16px Work Sans, sans-serif;
                    width: 100%;
                }
                
              `}</style>
            </>
        );
        
    }//END renderDescription


    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderAdoptionCallForAction
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderAdoptionCallForAction() {

        return (
            <>
                <div className="adapt-callforaction-container">
                    
                    <div className="adopt-callforaction-title">Adoption</div>
                    <div className="adopt-callforaction-text">
                        
                        Choosing to adopt a feral cat can be a rewarding and impactful decision.
                        Feral cats, often born and raised in the wild, have developed remarkable
                        survival skills and independence. By providing them with a safe and
                        loving home, you not only offer them a chance at a better life but also
                        contribute to the overall well-being of the feral cat population. With
                        patience, understanding, and proper socialization, feral cats can form
                        deep bonds with their adopters, becoming loyal and affectionate
                        companions. Adopting a feral cat not only brings joy and companionship
                        into your life but also helps break the cycle of feral cat
                        overpopulation, ensuring a brighter future for these often misunderstood
                        and deserving feline friends.
                        
                        <br/><br/>

                        As we are in our busy season and vet costs have increased, we have updated 
                        our adoption prices.

                        Each cat and kitten is vet checked, FIV / FELV tested, given revolution, 
                        first vaccination and provender.
                        <br/><br/>
                        
                        $175 spayed or neutered cats or big kittens.
                        <br/>
                        
                        $125 for kittens and once you provide proof of spay/neuter you will receive 
                        $50 back.
                        <br/><br/>

                        We will check references that you provide and we also need the name of your 
                        vet you will be going to. Please use the form below for applying to adopt 
                        a cat/kitten. 
                        <br /><br />
                        ❤ Thank you ❤
                        
                        <br/><br/>
                        
                    </div>
                    
                    <iframe
                        title="Gracie's Adoption Form"
                        src={linkConstants.adoptionForm}
                        frameborder="0"
                        marginheight=""
                        marginwidth="0"
                        style={{ 
                            width: "100%", 
                            height: "2100px",
                            marginTop: "32",
                        }}
                    >
                        Loading…
                    </iframe>                
                </div>
                    
                <br />
                <br />
                
                <style jsx="true">{`
                    
                .adapt-callforaction-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    //margin-top: 72px;
                    //padding: 0 104px 0 88px;
                    font: 400 16px Work Sans, sans-serif;
                }
                    
                .adopt-callforaction-title {
                  color: var(--Neutral-800, #292e3d);
                  text-align: center;
                  align-self: center;
                  margin-top: 63px;
                  font: 700 28px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .adopt-callforaction-title {
                    margin-top: 40px;
                  }
                }
                .adopt-callforaction-text {
                    color: var(--Neutral-600, #525b7a);
                    text-align: left;
                    justify-content: center;
                    justify-items: center;
                    align-self: center;
                    align-content: center;
                    align-items: center;
                    margin-top: 15px;
                    //padding: 0 107px 0 73px;
                    width: 100%;
                    font: 400 16px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .adopt-callforaction-text {
                        padding: 0px;
                        width: 80%;
                    }
                }
              `}</style>
            </>
        );
    
    }//END renderAdoptionCallForAction
    

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render() {

        return (
            <div>
                {this.renderBanner()}
                {/*<br /><br />*/}
                {/*<AdoptionCarousel onClickCallback={this.onAdopteeClick}/>*/}
                {/*{this.renderDescription()}*/}
                {this.renderAdoptionCallForAction()}
                
                {renderSocials()}
                {renderFooter()}
            </div>
        );

    }//END render


}//END class Landing


export default Adoption;
