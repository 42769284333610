import { FETCH_USER } from "../actions/types";

// Responsible for adding the user model data to the global state/store
//
export default function(state = {}, action) {
    
    switch (action.type) {
        case FETCH_USER:
            return action.payload || false;
        default:
            return state;
    }//END switch
    
}//END authReducer
