import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {getAdopteeInfoBlob, translate} from "../i18n";

import Carousel from "react-multi-carousel";
import AdoptionCard from "./common/AdoptionCard";

import adoptee1Img from "../assets/images/cat-adoptee-1.png";
import adoptee2Img from "../assets/images/cat-adoptee-2.png";
import adoptee3Img from "../assets/images/cat-adoptee-3.png";
import adoptee4Img from "../assets/images/cat-adoptee-4.png";




class AdoptionCarousel extends React.Component {

    
    static propTypes = {
        onClickCallback: PropTypes.func
    };


    static defaultProps = {
        onClickCallback: undefined
    };
    
    

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render() {

        const adoptee1Info = getAdopteeInfoBlob(0);
        const adoptee2Info = getAdopteeInfoBlob(1);
        const adoptee3Info = getAdopteeInfoBlob(2);
        const adoptee4Info = getAdopteeInfoBlob(3);
        
        const { onClickCallback } = this.props;

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: {max: 4000, min: 3000},
                items: 5
            },
            desktop: {
                breakpoint: {max: 3000, min: 1024},
                items: 3
            },
            tablet: {
                breakpoint: {max: 1024, min: 464},
                items: 2
            },
            mobile: {
                breakpoint: {max: 464, min: 0},
                items: 1
            }
        };

        return (

            <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true} //this.props.deviceType !== "mobile" ? true : false
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="transform 200ms ease-in-out"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                <AdoptionCard
                    image={adoptee1Img}
                    name={adoptee1Info.name}
                    description={adoptee1Info.shortDescription}
                    onClickCallback={onClickCallback}
                />

                <AdoptionCard
                    image={adoptee2Img}
                    name={adoptee2Info.name}
                    description={adoptee2Info.shortDescription}
                    onClickCallback={onClickCallback}
                />

                <AdoptionCard
                    image={adoptee3Img}
                    name={adoptee3Info.name}
                    description={adoptee3Info.shortDescription}
                    onClickCallback={onClickCallback}
                />

                <AdoptionCard
                    image={adoptee4Img}
                    name={adoptee4Info.name}
                    description={adoptee4Info.shortDescription}
                    onClickCallback={onClickCallback}
                />


            </Carousel>

        );

    }//END render

}//END AdoptionCarousel

function mapStateToProps(state) {

    return {
        auth: state.auth,
        currentAdopteeName: state.currentAdopteeName
    };

}//END mapStateToProps


export default connect(mapStateToProps)(AdoptionCarousel);
