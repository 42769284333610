import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import styleConstants from "../constants/styleConstants";
import renderSocials from "./common/RenderSocials";
import renderFooter from "./common/RenderFooter";

import banner from "../assets/images/banners/resources-banner.png";



class Resources extends Component {


    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderBanner
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderBanner() {

        return (
            <>
                <div className="banner-container">
                    <img
                        loading="lazy"
                        srcSet={banner}
                        className="banner-img"
                    />
                    <div className="banner-title-text">
                        Resources & Information
                    </div>
                </div>
                <style jsx>{`
                    .banner-container {
                        display: flex;
                        flex-direction: column;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        position: relative;
                        width: 100%;
                        align-items: start;
                        overflow: hidden;
                        min-height: 557px;
                        color: #fff;
                        justify-content: center;
                        padding: 206px 60px 168px 87px;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .banner-container {
                            max-width: 100%;
                            padding: 40px 20px 40px 30px;
                        }
                    }
                    .banner-img {
                        position: absolute;
                        inset: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                    .banner-title-text {
                        position: absolute;
                        top: 10%;
                        align-self: center;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        color: #fff;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .banner-title-text {
                            top: 10%;
                            left: 10%;
                        }
                    }

                `}</style>
            </>
        );

    }//END renderBanner


    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderDescription
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderDescription() {

        return (
            <>

                <div className="main-container">

                    <div className="main-container-2">
                        
                    
                        <div className="main-title">What Is A Feral Cat?</div>
                        <div className="desc-container1">
                            <div className="paragraph-container2">
                                <div className="paragraph-text">
                                    Feral cats are cats that were born and raised in the wild or have been 
                                    abandoned and had to fend for themselves. Unlike pet cats, feral cats 
                                    haven't had much human interaction, so they may be afraid of people and 
                                    prefer to live outside. They are very independent and have developed 
                                    survival skills to find food and shelter on their own.
                                </div>
                            </div>
                        </div>
    
                        <div className="paragraph-container1">
                            <div className="paragraph-title">How You Can Help</div>
                            <br/>
                            <div className="paragraph-container2">
                                <div className="paragraph-text">
                                    When it comes to caring for feral cats, there are a few important 
                                    things to keep in mind. Firstly, it's essential to provide them with 
                                    a safe and comfortable shelter, such as a cozy outdoor cat house or a 
                                    specially designed feral cat shelter. These shelters protect them 
                                    from harsh weather and give them a safe place to rest.
                                    <br /><br />
                                    Feeding feral cats is also important. Leaving out food and fresh water 
                                    in a designated feeding area helps ensure they have enough to eat and 
                                    drink. It's best to use sturdy bowls or dishes that won't tip over easily.
                                    Providing regular meals helps them stay healthy and can also help you 
                                    build trust with them over time.
                                    <br /><br />
                                    While feral cats may not be comfortable with close human interaction, 
                                    it's still crucial to look out for their well-being. Keeping an eye on 
                                    their overall health is important. If you notice any signs of illness 
                                    or injury, it's best to contact a local animal shelter or a veterinarian 
                                    who can provide advice on how to help them.
                                </div>
                            </div>
                        </div>
    
    
                        <div className="paragraph-container1">
                            <div className="paragraph-title">Respect Their Boundaries</div>
                            <br/>
                            <div className="paragraph-container2">
                                <div className="paragraph-text">
                                    Remember, it's essential to respect a feral cat's boundaries and not force
                                    them to interact with you. Over time, some feral cats may become more comfortable 
                                    around people, but it's a slow process that requires patience and understanding.
                                    You can earn their trust by being consistent, providing food and shelter, 
                                    and giving them space when they need it.
                                    <br /><br />
                                    If you want to make a difference in the lives of feral cats, you can support local
                                    feral cat rescue organizations or volunteer to help with trap-neuter-return (TNR) 
                                    programs. TNR involves safely trapping feral cats, spaying or neutering them to 
                                    prevent overpopulation, and returning them to their outdoor homes.
                                    <br /><br />
                                    By caring for feral cats and helping them live healthier lives, you are making 
                                    a positive impact on their well-being and contributing to the welfare of the cat
                                    community as a whole. Your compassion and efforts can truly make a difference 
                                    in their lives.
                                </div>
                            </div>
                        </div>
    
                        <br />
                        
                        <div className={"desc-container1"}>
                            <Link 
                                className="call-to-action-btn"
                                to="/contact"
                            >
                                VOLUNTEER TODAY!
                            </Link>
                        </div>
                        
                        <br />
                        <br />

                    </div>
                </div>

                <style jsx>{`

                    .main-container {
                        //background-color: var(--Neutral-800, #97aef1);
                        align-self: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 48px;
                        width: 100%;
                        //max-width: 812px;
                        flex-direction: column;
                        padding: 0 20px;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .main-container {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }

                    .main-container-2 {
                        align-self: center;
                        display: flex;
                        justify-content: center;
                        justify-items: center;
                        align-content: center;
                        align-items: center;
                        width: 100%;
                        flex-direction: column;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .main-container-2 {
                            width: 80%;
                        }
                    }

                    .main-title {
                        color: var(--Neutral-800, #292e3d);
                        text-align: center;
                        align-self: center;
                        font: 700 28px Work Sans, sans-serif;
                    }

                    .desc-container1 {
                        margin-top: 64px;
                        max-width: 812px;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .desc-container1 {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }

                    .paragraph-container2 {
                        gap: 20px;
                        display: flex;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .paragraph-container2 {
                            flex-direction: column;
                            align-items: stretch;
                            gap: 0px;
                        }
                    }

                    .desc-text {
                        color: var(--Neutral-600, #525b7a);
                        font: 400 16px Work Sans, sans-serif;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .desc-text {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }

                    .desc-column1 {
                        display: flex;
                        flex-direction: column;
                        line-height: normal;
                        width: 72%;
                        margin-left: 0px;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .desc-column1 {
                            width: 100%;
                        }
                    }

                    .desc-column2 {
                        display: flex;
                        flex-direction: column;
                        line-height: normal;
                        width: 28%;
                        margin-left: 20px;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .desc-column2 {
                            width: 100%;
                        }
                    }

                    .call-to-action-btn {
                        justify-content: center;
                        border-radius: 4px;
                        background-color: #ff7727;
                        margin-top: 17px;
                        color: var(--Neutral-White, #fff);
                        text-align: center;
                        //width: 100%;
                        cursor: pointer;
                        padding: 12px;
                        font: 600 18px Work Sans, sans-serif;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-call-to-action-btn {
                            margin-top: 40px;
                        }
                    }

                    .paragraph-container1 {
                        margin-top: 32px;
                        max-width: 812px;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .paragraph-container1 {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }

                    .paragraph-title {
                        color: var(--Neutral-800, #292e3d);
                        margin-top: 70px;
                        font: 700 28px Work Sans, sans-serif;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .paragraph-title {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }

                    .paragraph-text {
                        color: var(--Neutral-600, #525b7a);
                        //margin-top: 26px;
                        font: 400 16px Work Sans, sans-serif;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .paragraph-text {
                            max-width: 100%;
                        }
                    }

                `}</style>
            </>
        );

    }//END renderDescription


    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render() {

        return (
            <div>
                {this.renderBanner()}
                {this.renderDescription()}
                {renderSocials()}
                {renderFooter()}
            </div>
        );

    }//END render


}//END class Landing

export default connect()(Resources);
