import english from './en';

export const DEFAULT_LOCALE = 'en';

export const Translations = {
    en: english,
};


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
//     translate
//------------------------------------------
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
export function translate(message, locale = DEFAULT_LOCALE) {

    // We're actually asking for 'something' to be translated
    if (message) {
        
        // The translation exists AND the message exists in this translation
        if (Translations[locale] && Translations[locale][message])
            return Translations[locale][message];
        
        // Otherwise try in the default translation
        if (Translations[DEFAULT_LOCALE] && Translations[DEFAULT_LOCALE][message]) 
            return Translations[DEFAULT_LOCALE][message];
        
    }//END if

    return '???';
    
}//END translate


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
//     getAdopteeInfoBlob
//------------------------------------------
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
export function getAdopteeInfoBlob(adopteeIndex, locale = DEFAULT_LOCALE) {

    if (adopteeIndex > -1) {

        // The translation exists AND the message exists in this translation
        if (Translations[locale] && Translations[locale]["adoptees"][adopteeIndex])
            return Translations[locale]["adoptees"][adopteeIndex];

        // Otherwise try in the default translation
        if (Translations[DEFAULT_LOCALE] && Translations[DEFAULT_LOCALE]["adoptees"][adopteeIndex])
            return Translations[DEFAULT_LOCALE]["adoptees"][adopteeIndex];

    }//END if
    
    return '???';
    
}//END getAdopteeInfoBlob


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
//     getAdopteeInfoBlobByName
//------------------------------------------
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
export function getAdopteeInfoBlobByName(adopteeName, locale = DEFAULT_LOCALE) {

    if (adopteeName) {

        // The translation exists AND the message exists in this translation
        if (Translations[locale] && Translations[locale]["adoptees"])
            return Translations[locale]["adoptees"].find(adoptee => adoptee.name.toLowerCase() === adopteeName.toLowerCase());

        // Otherwise try in the default translation
        if (Translations[DEFAULT_LOCALE] && Translations[DEFAULT_LOCALE]["adoptees"])
            return Translations[DEFAULT_LOCALE]["adoptees"].find(adoptee => adoptee.name.toLowerCase() === adopteeName.toLowerCase());
        
    }//END if

    return '???';
    
}//END getAdopteeInfoBlobByName

        