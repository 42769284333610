// TODO: Refactor to StripeCheckout
// TODO: Update to use CAD currency

import React, { Component } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { connect } from 'react-redux';

import * as actions from "../actions";


class Payments extends Component {


    render() {
        return (
            <StripeCheckout
                name="Gracie's Feral Cat Rescue"
                description="$5 for 5 credits"
                amount={500} // $5.00
                token={token => this.props.handleToken(token)} // Token received from Stripe 
                stripeKey={process.env.REACT_APP_STRIPE_KEY}
            >
                <button className="btn">
                    Add Credits
                </button>
            </StripeCheckout>
        );
    }//END render
    
}//END class Payments

export default connect(null, actions)(Payments);
