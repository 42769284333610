export default {
    
    login: 'Login',
    email: 'Email',
    password: 'Password',
    
    emailSendSuccess: "Email sent, thank you!\n\nWe will get back to you as soon as we can. 🐾❤🐾",
    emailSendError: "Error!\n\nSorry, we could not send that email at this time.",
    
    errorAllFields: "Please fill in all fields.",
    errorInvalidEmail: "Please enter a valid email.",
    errorInvalidPhone: "Please enter a valid phone number.",
    errorInvalidPassword: "Please enter a valid password.",
    errorInvalidEmailPassword: "Invalid email or password.",
    
    adoptees: [
        {
            id: 0,
            name: 'Douglas',
            title: '🌟 Meet Douglas: A Hero of the Streets 🐾',
            shortDescription: "Douglas, with his soft gray fur and soulful eyes, has shown remarkable resilience " +
                "in surviving the harsh realities of life as a stray. Now seeking a loving home, Douglas embodies" +
                " the spirit of perseverance and the promise of a brighter future filled with trust and affection.",
            paragraph1: "Douglas's story is one of remarkable resilience and unyielding spirit. " +
                "With his soft gray fur and soulful eyes, Douglas has faced the harsh realities of life " +
                "as a stray with unmatched courage. Found wandering back alleys, his journey from a " +
                "frightened stray to a hopeful feline seeking a forever home is a testament to the " +
                "strength that resides in all of us.",
            paragraph2: "Despite his trials, Douglas dreams of a quiet corner where he can finally rest," +
                " a warm lap to curl up in, and a gentle hand to reassure him that the world isn't as cruel " +
                "as it once seemed. His eyes now gleam with a spark of hope, reflecting the promise of a brighter future.",
            paragraph3: "By opening your heart to Douglas, you are not just adopting a cat; you are embracing" +
                " a survivor, a symbol of perseverance, and a source of endless affection. Will you be the one " +
                "to give Douglas the safe haven he so richly deserves? Together, you can write the next" +
                " chapter of love and healing. 🌟🐾❤️",
        },
        {
            id: 1,
            name: 'ChewChew',
            title: "🌟 Meet ChewChew: Nurturing Guardian with a Chewing Charm 🐾",
            shortDescription: "ChewChew is a nurturing cat with a heart of gold, known for her loving care of stray cats" +
                " in need and her adorable habit of chewing. With her soft tabby fur and playful personality, she brings " +
                "warmth and joy to any home lucky enough to have her.",
            paragraph1: "ChewChew is a delightful cat whose nurturing heart and quirky habit of chewing have made her a beloved" +
                " figure among the local strays. With her soft tabby fur and bright, inquisitive eyes, ChewChew has always had" +
                " a special knack for sensing when another cat is in need. From providing warmth and companionship to sharing her" +
                " food, ChewChew has taken it upon herself to be the caretaker of her colony, ensuring that no stray is ever left alone or hungry.",
            paragraph2: "Beyond her compassionate nature, ChewChew is known for her adorable and amusing love of chewing. Whether " +
                "it's a cozy blanket, a favorite toy, or even a well-worn shoe, she finds comfort and joy in her quirky habit. This " +
                "unique trait adds a playful charm to her already endearing personality, making every moment spent with her filled with laughter and love.",
            paragraph3: "Now, ChewChew is looking for a forever home where her nurturing instincts and love of chewing can be embraced." +
                " She dreams of a place where she can continue to care for those around her while enjoying the simple pleasures that bring " +
                "her happiness. By adopting ChewChew, you’re welcoming a cat with a heart of gold and a playful spirit, ready to fill your " +
                "home with affection and joy. Will you be the one to give ChewChew the loving sanctuary she so richly deserves? 🌟🐾❤️",
        },
        {
            id: 2,
            name: 'PoofPoof',
            title: '🌟 Meet PoofPoof: A Tale of Cuteness 🐾',
            shortDescription: "PoofPoof, with her golden soft fur and golden eyes, has overcome the challenges of living " +
                "in the wild with remarkable strength and hope. She is now seeking a patient, " +
                "compassionate home where she can continue her journey of trust and love.",
            paragraph1: "PoofPoof's story is a mesmerizing blend of strength and hope. With her lustrous ginger fur, " +
                 "she stands as a testament to survival, having navigated the challenges of the wild with unwavering determination. " +
                 "From cautious glances to the softest purrs, PoofPoof's journey showcases the beauty of trust blossoming slowly," +
                " like the most delicate of flowers.",
            paragraph2: "Seeking a patient and understanding home, PoofPoof yearns to grace someone's life with her presence. " +
                "She envisions a place where the past is embraced, and a future filled with gentle companionship is waiting " +
                "to unfold. PoofPoof's golden eyes, once reflecting the mysteries of starlit nights, now shine with the promise" +
                " of brighter tomorrows.",
            paragraph3: "Will you be the chapter in PoofPoof's story that transforms her tale into one of warmth" +
                " and love? By welcoming PoofPoof into your world, you're inviting the embodiment of resilience " +
                "and the beauty of second chances. Come, meet PoofPoof, and be enchanted by the possibility of " +
                "being her guiding light through the journey ahead. 🌟🐾❤️",
        },
        {
            id: 3,
            name: 'Marshall',
            title: "🌟 Meet Marshall: The Feline Advocate for Justice 🐾",
            shortDescription: "Marshall, with his sleek black-and-white coat, has always strived for justice and protection for" +
                " his fellow strays. Now seeking a forever home, this noble feline hopes to bring his unyielding spirit " +
                "and compassion into a loving environment.",
            paragraph1: "Marshall's story is one of unwavering determination and a quest for fairness, not just for himself," +
                " but for every stray he encountered. With his sleek black-and-white coat, reminiscent of a dapper tuxedo, " +
                "Marshall has always stood out, not just in appearance but in spirit. Found in the heart of a bustling neighborhood," +
                " Marshall was known as the guardian of his colony, always looking out for his fellow strays, ensuring they " +
                "had food and protection from the harsh realities of street life.",
            paragraph2: "This noble feline has a heart as big as his courage. Even in the face of adversity, Marshall never" +
                " wavered in his mission to create a safe environment for those around him. His keen instincts and " +
                "compassionate nature have earned him the respect and admiration of many. Despite his struggles, Marshall " +
                "remains hopeful, his bright green eyes reflecting his unyielding belief in a better world for all.",
            paragraph3: "Now, Marshall is searching for a forever home where his heroic spirit can be cherished " +
                "and where he can finally rest, knowing he’s safe and loved. He dreams of a place where his journey" +
                " for justice is met with warmth and kindness. By adopting Marshall, you’re welcoming a true hero " +
                "into your home, a cat who will bring not only companionship but also a profound sense of purpose and" +
                " resilience. Will you be the one to offer Marshall the sanctuary he so richly deserves? 🌟🐾❤️",
        }
    ],
    
};