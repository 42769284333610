import React from 'react';

import Button from './Button';

import styleConstants from '../../constants/styleConstants';
import infoConstants from "../../constants/infoConstants";
import linkConstants from '../../constants/linkConstants';

import gfcrLogo from "../../assets/images/gfcr-logo[128x128].png";

import fbIcon from "../../assets/icons/social-icon-facebook.png";
import instagramIcon from "../../assets/icons/social-icon-instagram.png";
import linkedinIcon from "../../assets/icons/social-icon-linkedin.png";
import pinterestIcon from "../../assets/icons/social-icon-pinterest.png";
import twitterIcon from "../../assets/icons/social-icon-twitter.png";
import youtubeIcon from "../../assets/icons/social-icon-youtube.png";


// TODO: Hook up to newsletter-subscription action
function subscribeToNewsletter() {
    
    alert("Newsletters Coming Soon!");
    
}//END subscribeToNewsletter


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
//     renderSocials
//------------------------------------------
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
export default function renderSocials() {
    
    return (
        <>
            <div className="socials-container">

                <div className="socials-inner-container-left">
                    <div className="contact-address">
                        <div className="text-label">Serving:</div>
                        <div className="text-value">{infoConstants.geoAddress}</div>
                    </div>
                    {/*<div className="contact-phone">*/}
                    {/*    <div className="text-label">Phone:</div>*/}
                    {/*    <div className="text-value">{infoConstants.phoneNumber}</div>*/}
                    {/*</div>*/}
                    <div className="contact-email">
                        <div className="text-label">Email:</div>
                        <div className="text-value">{infoConstants.emailAddress}</div>
                    </div>
                    <div className="logo-container">
                        <img
                            loading="lazy"
                            srcSet={gfcrLogo}
                            className="logo-small"
                        />
                        <div className="logo-text">Gracie’s Cat Rescue</div>
                    </div>
                </div>

                <div className="socials-inner-container-right">
                    <div className="text-label">Newsletter:</div>
                    <div className="subscribe-container">
                        <div className="email-input-field">Your email here</div>
                        <Button 
                            className="orange-sub-button"
                            onClick={subscribeToNewsletter}
                        >
                            Subscribe
                        </Button>
                    </div>
                    
                    <div className="text-label">Social:</div>
                    <div className="social-link-container">
                        
                        <Button
                            className="social-link-icon"
                            onClick={() => window.open(linkConstants.facebook, "_blank")}
                            >
                            <img
                                loading="lazy"
                                src={fbIcon}
                                style={{border: "none", width: "100%", height: "100%"}}
                            />
                        </Button>

                        <Button
                            className="social-link-icon"
                            onClick={() => window.open(linkConstants.instagram, "_blank")}
                        >
                            <img
                                loading="lazy"
                                src={instagramIcon}
                                style={{border: "none", width: "100%", height: "100%"}}
                            />
                        </Button>                        

                        {/*<Button*/}
                        {/*    className="social-link-icon"*/}
                        {/*    onClick={() => window.open(linkConstants.linkedin, "_blank")}*/}
                        {/*>                       */}
                        {/*    <img*/}
                        {/*        loading="lazy"*/}
                        {/*        src={linkedinIcon}*/}
                        {/*        className="social-link-icon"*/}
                        {/*    />*/}
                        {/*</Button>*/}

                        {/*<Button*/}
                        {/*    className="social-link-icon"*/}
                        {/*    onClick={() => window.open(linkConstants.pinterest, "_blank")}*/}
                        {/*>*/}
                        {/*    <img*/}
                        {/*        loading="lazy"*/}
                        {/*        src={pinterestIcon}*/}
                        {/*        className="social-link-icon"*/}
                        {/*    />*/}
                        {/*</Button>                        */}

                        <Button
                            className="social-link-icon"
                            onClick={() => window.open(linkConstants.twitter, "_blank")}
                        >
                            <img
                                loading="lazy"
                                src={twitterIcon}
                                className="social-link-icon"
                            />
                        </Button>

                        {/*<Button*/}
                        {/*    className="social-link-icon"*/}
                        {/*    onClick={() => window.open(linkConstants.youtube, "_blank")}*/}
                        {/*>*/}
                        {/*    <img*/}
                        {/*        loading="lazy"*/}
                        {/*        src={youtubeIcon}*/}
                        {/*        className="social-link-icon"*/}
                        {/*    />*/}
                        {/*</Button>                        */}
                        
                    </div>
                </div>

            </div>
            <style jsx>{`
                .socials-container {
                    display: flex;
                    margin-top: 17px;
                    height: 100%;
                    width: 100%;
                    gap: 20px;
                    justify-content: space-between;
                    padding: 48px 16px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .socials-container {
                        max-width: 100%;
                        flex-wrap: wrap;
                        padding: 0 20px;
                    }
                }

                .socials-inner-container-left {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    font-size: 15px;
                    width: 35%;
                    height: 180px;
                    margin-bottom: 32px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .socials-inner-container-left {
                        width: 100%;
                        margin-bottom: 48px;
                    }
                }
                
                .socials-inner-container-right {
                    display: flex;
                    flex-direction: column;
                    align-self: stretch;
                    width: 35%;
                    height: 180px;
                    margin-bottom: 32px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .socials-inner-container-right {
                        width: 100%;
                        margin-bottom: 48px;
                    }
                }
                
                .contact-address {
                    display: flex;
                    margin-left: 10px;
                    gap: 9px;
                }

                .text-label {
                    color: #4030b7;
                    font-family: Work Sans, sans-serif;
                    font-weight: 500;
                    font-variant: all-small-caps;
                }

                .text-value {
                    color: var(--Neutral-800, #292e3d);
                    font-family: Work Sans, sans-serif;
                    font-weight: 400;

                }

                .contact-phone {
                    display: flex;
                    gap: 20px;
                    justify-content: space-between;
                    margin: 24px 0 0 10px;
                }

                .contact-email {
                    display: flex;
                    gap: 20px;
                    white-space: nowrap;
                    justify-content: space-between;
                    margin: 23px 0 0 10px;
                }

                .logo-container {
                    align-self: stretch;
                    display: flex;
                    margin-top: 16px;
                    gap: 3px;
                    font-size: 22px;
                    color: #4030b7;
                    font-weight: 700;
                }

                .logo-small {
                    aspect-ratio: 1;
                    object-fit: auto;
                    object-position: center;
                    width: 48px;
                }

                .logo-text {
                    font-family: Work Sans, sans-serif;
                    font-style: italic;
                    flex-grow: 1;
                    flex-basis: auto;
                    margin: auto 0;
                }


                .subscribe-container {
                    display: flex;
                    margin-top: 18px;
                    margin-bottom: 36px;
                    gap: 6px;
                }

                .email-input-field {
                    border-radius: 4px;
                    border-color: rgba(224, 227, 235, 1);
                    border-style: solid;
                    border-width: 1px;
                    background-color: #fff;
                    align-self: start;
                    color: var(--Neutral-300, #a3aac2);
                    justify-content: center;
                    padding: 8px;
                    width: 200px;
                    font: 400 18px Work Sans, sans-serif;
                }

                .orange-sub-button {
                    justify-content: center;
                    border: none;
                    border-radius: 4px;
                    background-color: #ff7727;
                    color: var(--Neutral-White, #fff);
                    white-space: nowrap;
                    text-align: center;
                    cursor: pointer;
                    padding: 10px 15px;
                    font: 600 13px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .orange-sub-button {
                        white-space: initial;
                    }
                }
                
                .social-link-container {
                    display: flex;
                    margin-top: 8px;
                    gap: 16px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .social-link-container {
                        padding-right: 20px;
                    }
                }

                
                .social-link-icon {
                    //background-color: coral;
                    aspect-ratio: 1;
                    object-fit: cover;
                    object-position: center;
                    width: 36px;
                    height: 36px;
                    border: none;
                    border-radius: 32px;
                    padding: 0px;
                    cursor: pointer;
                }
            `}</style>
        </>
    );

}//END renderFooter

