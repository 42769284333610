import React, { Component } from 'react';
import PropTypes from "prop-types";

class Button extends Component {

    static propTypes = {
        className: PropTypes.string,
        border: PropTypes.string,
        borderRadius: PropTypes.string,
        color: PropTypes.string,
        children: PropTypes.node,
        height: PropTypes.string,
        width: PropTypes.string,
        
        onClick: PropTypes.func,
    };
    
    static defaultProps = {
        className: "",
        border: "none",
        borderRadius: "2px",
        color: "#4030b7",
        children: null,
        height: "42px",
        width: "128px",
        
        onClick: () => {},
    };


    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render() {
        
        const {
            className,
            border,
            borderRadius,
            color,
            children,
            height,
            width,
            onClick
        } = this.props;
            
    
        return (
            <button
                className={className}
                onClick={onClick}
                // style={{
                //     backgroundColor: color,
                //     border,
                //     borderRadius,
                //     height,
                //     width
                // }}
            >
                {children}
            </button>
        );
    
        
    }//END render
    
}//END Button

export default Button;
